import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const AddItem = () => {

    const navigate = useNavigate();
    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

    const [CategoryData, setCategoryData] = useState([]);
    const [SubcategoryData, setSubcategoryData] = useState([]);
    const [chSubcategoryData, setChSubcategoryData] = useState([]);
    const [productsData, setProductsData] = useState([]);

    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [salePrice, setSalePrice] = useState(0);
    const [orgPrice, setOrgPrice] = useState(0);
    const [rate, setRate] = useState("4");
    const [tag, setTag] = useState("");
    const [tagline, setTagline] = useState("");
    const [description, setDescription] = useState("");
    const [curImage, setCurImage] = useState("");
    const [itemImageNames, setItemImageNames] = useState([]);
    
    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);
    const [adding, setAdding] = useState(false);
    
    const [chId, setChId] = useState(0);
    const [chItemName, setChItemName] = useState("");
    const [chCategory, setChCategory] = useState("");
    const [chSubcategory, setChSubcategory] = useState("");
    const [chSalePrice, setChSalePrice] = useState(0);
    const [chOrgPrice, setChOrgPrice] = useState(0);
    const [chRate, setChRate] = useState("4");
    const [chTag, setChTag] = useState("");
    const [chTagline, setChTagline] = useState("");
    const [chDescription, setChDescription] = useState("");
    const [chCurImage, setChCurImage] = useState("");
    const [chItemImageNames, setChItemImageNames] = useState([]);
    
    const [pageSize, setPageSize] = useState(10);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const columns = [
        {field: "srno", headerName: "#", headerAlign: "center", width: 100, align: "center"},
        {field: "title", headerName: "ITEM", headerAlign: "center", width: 120, align: "left"},
        {field: "category", headerName: "CATEGORY", headerAlign: "center", width: 100, align: "center"},
        {field: "finalPrice", headerName: "SALE PRICE", headerAlign: "center", width: 100, align: "center"},
        {field: "edit", headerName: "EDIT", headerAlign: "center", align: "center", width: 100, 
            renderCell: (params) => {
                return (
                    <div className="table-edit-button">
                        <button onClick={() => handleEdit(params.row)}>Edit</button>
                    </div>
                )
            }
        },
        {field: "delete", headerName: "DELETE", headerAlign: "center", align: "center", width: 100, 
            renderCell: (params) => {
                return (
                    <div className="table-delete-button">
                        <button onClick={() => handleDelete(params.row)}>Delete</button>
                    </div>
                )
            }
        },
    ];
    
    useEffect(() => {
        httpClient.get('/categoryData')
            .then(res => {
                // console.log(res);
                setCategoryData(res.data);
            });

        httpClient.get('/allProductsData')
        .then(res => {
                let tmp1 = [];
                let tmp2 = [];
                if(res.data) {
                    res.data.forEach((itm) => {
                        if(itm.category==="All"){
                            tmp1.push(itm);
                        } else {
                            tmp2.push(itm);
                        }
                    });
                    setProductsData(tmp2.concat(tmp1).map((itm, ind) => ({
                        ...itm,
                        srno: ind + 1
                    })));
                }
            });
        
    }, [alertError]);


    const handleSubmit = (event) => {
        event.preventDefault();
        let found = productsData.filter((item) => String(item.title).toLowerCase()===itemName.toLowerCase());
        if(found.length!==0) {
            setAlertCont("Item already exists!!");
            setAlertError(1);
        }
        else {
            setAdding(true);
            const finalItemName = itemName[0].toUpperCase() + itemName.slice(1).toLowerCase();
            httpClient.post('/allProductsData', {
                title: finalItemName,
                category: category,
                subcategory: subcategory,
                finalPrice: Number(salePrice),
                originalPrice: Number(orgPrice),
                tag: tag,
                tagline: tagline,
                description: description,
                rateCount: parseInt(rate),
                // images: itemImages,
                path: '/product-details/'
            })
            .then(() => {
                setAlertCont("New Item Added!!");
                setAlertError(2);
                setAdding(false);
                setItemName("");
                setCategory("");
                setSubcategory("");
                setSalePrice("");
                setOrgPrice("");
                setTag("");
                setTagline("");
                setDescription("");
                setRate("");
                // setItemImages([]);
                setItemImageNames([]);
            })
            .catch(() => {
                setAlertCont("Failed to Add!!");
                setAlertError(1);
                setAdding(false);
            });
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    };


    const handleEdit = (item) => {
        setChId(item.id);
        setChItemName(item.title);
        setChCategory(item.category);
        handleChSubCategory(item.category);
        setChSubcategory(item.subcategory);
        setChSalePrice(item.finalPrice);
        setChOrgPrice(item.originalPrice? item.originalPrice : "");
        setChTag(item.tag);
        setChTagline(item.tagline);
        setChDescription(item.description? item.description : "");
        setChRate(item.rateCount? item.rateCount : "1");
        setChItemImageNames(item.images);
        handleOpen();
    }

    const handleModalForm = (e) => {
        e.preventDefault();
        httpClient.put("/allProductsData/" + chId, {
            title: chItemName,
            category: chCategory,
            subcategory: chSubcategory,
            finalPrice: Number(chSalePrice),
            originalPrice: Number(chOrgPrice),
            tag: chTag,
            tagline: chTagline,
            description: chDescription,
            rateCount: parseInt(chRate),
            images: chItemImageNames
            })
            .then(() => {
                setAlertCont("Successfully Updated!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Update!!");
                setAlertError(1);
            });
        handleClose();
        
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    const handleDelete = (item) => {
        httpClient.delete("/allProductsData/" + item.id)
        .then(() => {
            setAlertCont("Succesfully Deleted!!");
            setAlertError(2);
        })
        .catch(() => {
            setAlertCont("Failed to Delete!!");
            setAlertError(1);
        });

        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }

    const handleSubCategory = (cat) => {
        setCategory(cat);
        setSubcategory("");

        httpClient.get('/subcategoryData')
        .then(res => {
            // console.log(res);
            setSubcategoryData(res.data.filter(item => item.category===cat));
        });
    }

    const handleChSubCategory = (cat) => {
        setChCategory(cat);
        setChSubcategory("");
        
        httpClient.get('/subcategoryData')
        .then(res => {
            setChSubcategoryData(res.data.filter(item => item.category===cat));
        });
    }

    const handleTag = (tagName) => {
        if(tagName==="hero-product") {
            setTag(tagName);
        }
        else {
            setTag(tagName);
            setTagline("");
        }
    }

    const handleChTag = (tagName) => {
        if(tagName==="hero-product") {
            setChTag(tagName);
        }
        else {
            setChTag(tagName);
            setChTagline("");
        }
    }

    const handleAddImage = () => {
        setItemImageNames([...itemImageNames, curImage])
        setCurImage("");
    }

    const handleChAddImage = () => {
        setChItemImageNames([...chItemImageNames, chCurImage])
        setChCurImage("");
    }

    const handleDeleteImage = (id) => {
        setItemImageNames(itemImageNames.filter((_, index) => index!==id));
    }

    const handleChDeleteImage = (id) => {
        setChItemImageNames(chItemImageNames.filter((_, index) => index!==id));
    }


    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/add-items">Items</Link>
                    </Breadcrumbs>
                )}

            <DocTitle title="Items"/>

            <section id="add-items">
                <div className="item-form">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form-group">
                            <label htmlFor="item-name">Item </label>
                            <input type="text" name="item-name" id="item-name" value={itemName} placeholder="Item Name" onChange={(e) => setItemName(e.target.value)} required/>
                            <div className="input-helper">Enter atleast 4 characters</div>
                        </div>
                        <div className="form-group select-input-grp">
                            <label htmlFor="cat-name">Category</label>
                            <select id="cat-name" name="cat-name" value={category} onChange={(e) => handleSubCategory(e.target.value)} required>
                                <option value="" disabled>Select Category</option>
                                { CategoryData.map((item,index) => (
                                    <option value={item.category} key={index}>{item.category}</option>
                                )) }
                            </select>
                            <div className="input-helper">Select the category</div>
                        </div>
                        <div className="form-group select-input-grp">
                            <label htmlFor="subcat-name">Subcategory</label>
                            { category==="All"? (
                                <input type="text" name="subcat-name" id="subcat-name" value={subcategory} onChange={(e) => setSubcategory(e.target.value)} required/>
                            ): (
                                <select id="subcat-name" name="subcat-name" value={subcategory} onChange={(e) => setSubcategory(e.target.value)} required>
                                    <option value="" disabled>Select Subcategory</option>
                                    { SubcategoryData.map((item,index) => (
                                        <option value={item.subcategory} key={index}>{item.subcategory}</option>
                                    )) }
                                </select>
                            )}
                            <div className="input-helper">Select the subcategory</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sale-price">Sale Price</label>
                            <input type="text" name="sale-price" id="sale-price" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} required={category!=="All"}/>
                            <div className="input-helper">Price ranging from 0</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="org-price">Original Price</label>
                            <input type="text" name="org-price" id="org-price" value={orgPrice} onChange={(e) => setOrgPrice(e.target.value)} />
                            <div className="input-helper">Price ranging from 0</div>
                        </div>
                        <div className="form-group select-input-grp">
                            <label htmlFor="rate">Rating</label>
                            <select id="rate" name="rate" value={rate} onChange={(e) => setRate(e.target.value)} >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                        <div className="form-group select-input-grp">
                            <label htmlFor="tag">Tag</label>
                            <select id="tag" name="tag" value={tag} onChange={(e) => handleTag(e.target.value)}>
                                <option value="">None</option>
                                <option value="hero-product">Hero Product</option>
                                <option value="featured-product">Featured Product</option>
                            </select>
                            <div className="input-helper">Select the Tag</div>
                        </div>
                        { tag==="hero-product" && (
                            <div className="form-group">
                                <label htmlFor="tagline">Tagline</label>
                                <input type="text" name="tagline" id="tagline" value={tagline} placeholder="Enter the Tagline" onChange={(e) => setTagline(e.target.value)} required/>
                                <div className="input-helper">Enter the Tagline</div>
                            </div>
                        )}
                        <div className="form-group">
                            <label htmlFor="item-desc">Description</label>
                            <textarea name="item-desc" id="item-desc" rows="5" value={description} placeholder="Describe something about it..." onChange={(e) => setDescription(e.target.value)} required></textarea>
                            <div className="input-helper">Describe the item</div>
                        </div>
                        <div className="form-group">
                            <label>Image</label>
                            <div className="images-group">
                                <ul className="images-div">
                                    {itemImageNames.length > 0 && itemImageNames.map((imageName, index) => (
                                        <li key={index} className="image-div">
                                            <p>{imageName}</p>
                                            <IconButton onClick={() => handleDeleteImage(index)}><HighlightOffIcon /></IconButton>
                                        </li>
                                    ))}
                                </ul>
                                { itemImageNames.length < 4 && (
                                    <>
                                        <input type="text" name="item-image" id="item-image" value={curImage} onChange={(e) => setCurImage(e.target.value)} style={{marginBottom: "10px"}} />
                                        <label htmlFor="item-image" className="add-image-btn" onClick={handleAddImage} >
                                            <AddIcon />
                                        </label>
                                    </>
                                )}
                            </div>
                            <div className="input-helper">Add the image paths</div>
                        </div>
                        { !adding && <button type="submit">Add</button>}
                        { adding && <button type="submit" className="active-btn" disabled>Adding...</button>}
                    </form>
                </div>
                
                <div className="item-table">
                    <DataGrid
                        columns={columns}
                        rows={productsData}
                        components={{Toolbar: GridToolbar}}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,25,50,100]}
                        sx={{
                            ".MuiDataGrid-columnHeaders" : {
                                backgroundColor: "var(--white-color-3)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-virtualScroller" : {
                                backgroundColor: "var(--white-color-1)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
                                alignItems: "flex-start",
                                whiteSpace: "normal",
                                overflow: "auto",
                                padding: "5px"
                            }
                        }}
                        disableSelectionOnClick
                    />
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="item-modal-updation-form">
                        <div className="modal-close-icon-div">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <form onSubmit={handleModalForm} className="form">
                            <div className="form-group">
                                <label htmlFor="item-name">Item </label>
                                <input type="text" name="item-name" id="item-name" value={chItemName} placeholder="Item Name" onChange={(e) => setChItemName(e.target.value)} required/>
                            </div>
                            <div className="form-group select-input-grp">
                                <label htmlFor="cat-name">Category</label>
                                <select id="cat-name" name="cat-name" value={chCategory} onChange={(e) => handleChSubCategory(e.target.value)} required>
                                    <option value="" disabled>Select Category</option>
                                    { CategoryData.map((item,index) => (
                                        <option value={item.category} key={index}>{item.category}</option>
                                    )) }
                                </select>
                            </div>
                            <div className="form-group select-input-grp">
                                <label htmlFor="subcat-name">Subcategory</label>
                                <select id="subcat-name" name="subcat-name" value={chSubcategory} onChange={(e) => setChSubcategory(e.target.value)} required>
                                    <option value="" disabled>Select Subcategory</option>
                                    { chSubcategoryData.map((item,index) => (
                                        <option value={item.subcategory} key={index}>{item.subcategory}</option>
                                    )) }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="sale-price">Sale Price</label>
                                <input type="text" name="sale-price" id="sale-price" value={chSalePrice} onChange={(e) => setChSalePrice(e.target.value)} required={chCategory!=="All"}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="org-price">Original Price</label>
                                <input type="text" name="org-price" id="org-price" value={chOrgPrice} onChange={(e) => setChOrgPrice(e.target.value)} />
                            </div>
                            <div className="form-group select-input-grp">
                                <label htmlFor="rate">Rating</label>
                                <select id="rate" name="rate" value={chRate} onChange={(e) => setChRate(e.target.value)} >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="form-group select-input-grp">
                                <label htmlFor="tag">Tag</label>
                                <select id="tag" name="tag" value={chTag} onChange={(e) => handleChTag(e.target.value)}>
                                    <option value="">None</option>
                                    <option value="hero-product">Hero Product</option>
                                    <option value="featured-product">Featured Product</option>
                                </select>
                            </div>
                            { chTag==="hero-product" && (
                                <div className="form-group">
                                    <label htmlFor="tagline">Tagline</label>
                                    <input type="text" name="tagline" id="tagline" value={chTagline} placeholder="Enter the Tagline" onChange={(e) => setChTagline(e.target.value)} required/>
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="item-desc">Description</label>
                                <textarea name="item-desc" id="item-desc" rows="5" value={chDescription} placeholder="Describe something about it..." onChange={(e) => setChDescription(e.target.value)} required></textarea>
                            </div>
                            <div className="form-group">
                                <label>Image</label>
                                <div className="images-group">
                                    <ul className="images-div">
                                        {chItemImageNames.length > 0 && chItemImageNames.map((imageName, index) => (
                                            <li key={index} className="image-div">
                                                <p>{imageName}</p>
                                                <IconButton onClick={() => handleChDeleteImage(index)}><HighlightOffIcon /></IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                    { chItemImageNames.length < 4 && (
                                        <>
                                            <input type="text" name="item-ch-image" id="item-ch-image" value={chCurImage} onChange={(e) => setChCurImage(e.target.value)} style={{marginBottom: "10px"}} />
                                            <label htmlFor="item-image" className="add-image-btn" onClick={handleChAddImage}>
                                                <AddIcon />
                                            </label>
                                        </>
                                    )}
                                </div>
                            </div>
                            <button type="submit">Update</button>
                        </form>
                    </div>
                </Modal>

            </section>
        </>
    )
};

export default AddItem;