import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import commonContext from "../contexts/common/commonContext";
import { useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import SidebarData from '../data/SidebarData';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const Sidebar = () => {

  const isNonMobile = useMediaQuery("(min-width: 868px)");
  const { isSidebarOpen, toggleSidebar, setUser, user, newOrdersLen, invoicesLen} = useContext(commonContext);

  const handleClick = (item) => {
      if(item.path==='/login/'){
        setUser(null);
        localStorage.clear();
      }
  }
  
  return (
    <div id="sidebar" style={{width: `${isSidebarOpen? "280px": "0"}`, opacity: `${isSidebarOpen? "1": "0"}`, visibility: `${isSidebarOpen? "visible": "hidden"}`}}>
      <div className="sidebar-header">
          <span>Dashboard</span>
          {
            !isNonMobile && isSidebarOpen && (
              <IconButton className="close-icon" onClick={() => toggleSidebar(false)}>
                <MenuOpenIcon />
              </IconButton>
            )
          }
      </div>

      <hr style={{width: `${isSidebarOpen? "245px": "0"}`}}></hr>

      <div className="user-div">
          <div className='user-logo-div'>
            <AccountCircleRoundedIcon className='user-logo'/>
          </div>
          <div className="user-details">
            <p>Username: {user}</p>
            <p>Location: {"Head Office"}</p>
          </div>
      </div>

      <hr style={{width: `${isSidebarOpen? "245px": "0"}`}}></hr>
      
      <div className="all-controls">
        <ul>
          {
            SidebarData.map((item,index) => (
              <Link onClick={() => handleClick(item)} key={index} to={item.path}>
                <li>
                  <div className='icon'>{item.icon}</div>
                  <span>{item.title}</span>
                  {item.title==="New Orders" && newOrdersLen > 0 && (
                    <div className='sidebar-badge'>{newOrdersLen}</div>
                  )}
                  {item.title==="Invoices" && invoicesLen > 0 && (
                    <div className='sidebar-badge'>{invoicesLen}</div>
                  )}
                </li>
              </Link>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;