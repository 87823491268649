const commonReducer = (state, action) => {
    switch (action.type) {

        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                isSidebarOpen: action.payload.toggle
            };

        case 'SET_USER':
            return {
                ...state,
                user: action.payload.toggle
            };
        
        case 'REMOVE_USER':
            return {
                ...state,
                user: null
            };

        case 'SET_NEWORDERS_LEN':
            return {
                ...state,
                newOrdersLen: action.payload.toggle
            };

        case 'SET_INVOICES_LEN':
            return {
                ...state,
                invoicesLen: action.payload.toggle
            };

        default:
            return state;
    }
};

export default commonReducer;